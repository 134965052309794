/**
 * 前端枚举维护
 * ————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 **/

/**
 * 展示字段
 * @namespace `field` 类型 */
export const FIELD_TYPE_SHOW = 1
/**
 * 隐藏字段 - `description`
 * @namespace `field` 类型 */
export const FIELD_TYPE_HIDDEN = 2
/**
 * 存在内容展示字段
 * @namespace `field` 类型 */
export const FIELD_TYPE_EXIST = 3
/**
 * 隐藏字段 - `form`
 * @namespace `field` 类型 */
export const FIELD_TYPE_HIDDEN_FORM = 4
/**
 * 选择所需的字段 - `table`
 * @namespace `field` 类型 */
export const FIELD_TYPE_CHECK = 10


/**
 * 默认事件
 * @namespace 事件类型 */
export const EVENT_TYPE_DEFAULT = 1
/**
 * 响应事件
 * @namespace 事件类型 */
export const EVENT_TYPE_NORMAL = 2
/**
 * 详情事件
 * @namespace 事件类型 */
export const EVENT_TYPE_DETAIL = 3
/**
 * 关闭弹窗更新外部事件
 * @namespace 事件类型 */
export const EVENT_TYPE_CLOSE_REFRESH = 4
/**
 * table` 选择行操作事件
 * @namespace 事件类型 */
export const EVENT_TYPE_CHECK = 10
/**
 * table` 选择全部事件
 * @namespace 事件类型 */
export const EVENT_TYPE_CHECK_ALL = 11


/**
 * `table` 请求数据事件
 * @namespace 事件接口类型 */
export const EVENT_OPERATION_TYPE_TABLE = 40
/**
 * 列表请求数据事件
 * @namespace 事件接口类型 */
export const EVENT_OPERATION_TYPE_LIST = 41
/**
 * `layout` 请求事件
 * @namespace 事件接口类型 */
export const EVENT_OPERATION_TYPE_LAYOUT = 60
/**
 * 批量导入事件
 * @namespace 事件接口类型 */
export const EVENT_OPERATION_TYPE_IMPORT = 70


/**
 * 包在 `card` 内
 * @namespace 组合组件类型 */
export const COMBINATION_COMPONENT_TYPE_CARD = 1
/**
 * 单独组件
 * @namespace 组合组件类型 */
export const COMBINATION_COMPONENT_TYPE_NOMAL = 2
/**
 * 包含页面头
 * @namespace 组合组件类型 */
export const COMBINATION_COMPONENT_TYPE_PAGE = 3


/**
 * 普通表格
 * @namespace 表格类型 */
export const TABLE_TYPE_NORMAL = 1


/**
 * 默认，显示标题
 * @namespace 标题显示 */
export const CARD_SHOW_TITLE = 1


/**
 * 默认弹窗
 * @namespace 弹窗类型 */
export const MODAL_TYPE_DEFAULT = 1
/**
 * 上传弹窗 - 写死的
 * @namespace 弹窗类型 */
export const MODAL_TYPE_UPLOAD = 2
/**
 * 自我控制弹窗
 * @namespace 弹窗类型 */
export const MODAL_TYPE_SLEF_CONTROL = 3
/**
 * 步骤弹窗
 * @namespace 弹窗类型 */
export const MODAL_TYPE_STEP_CONTROL = 4
/**
 * 提示
 * @namespace 弹窗类型 */
export const INTERFACE_TYPE_TIPS = 5


/**
 * 隐藏查询字段
 * @namespace 查询字段类型 */
export const QUERY_STATE_HIDDEN = 1
/**
 * 普通查询字段
 * @namespace 查询字段类型 */
export const QUERY_STATE_NORMAL = 2
/**
 * 扩展查询字段
 * @namespace 查询字段类型 */
export const QUERY_STATE_EXTRA = 3
/**
 * 可选查询字段
 * @namespace 查询字段类型 */
export const QUERY_STATE_OPTIONAL = 4
/**
 * 可选查询字段
 * @namespace 查询字段类型 */
export const QUERY_STATE_GLOBAL = 5


/** 激活动态模板 */
export const DYNAMIC_TYPE_ACTIVE = 1


/**
 * 超大号弹框
 * @namespace 弹框尺寸 */
export const MODAL_SIZE_HUGE = 0
/**
 * 大号弹框
 * @namespace 弹框尺寸 */
export const MODAL_SIZE_LARGE = 1
/**
 * 中号弹框
 * @namespace 弹框尺寸 */
export const MODAL_SIZE_NORMAL = 2
/**
 * 小号弹框
 * @namespace 弹框尺寸 */
export const MODAL_SIZE_SMALL = 3


/**
 * 默认类型，当前页执行
 * @namespace 事件响应方式 */
export const INTERFACE_TYPE_DEFAULT = 1
/**
 * 二次确认
 * @namespace 事件响应方式 */
export const INTERFACE_TYPE_DOUBLE_CHECK = 2
/**
 * 配置化弹框
 * @namespace 事件响应方式 */
export const INTERFACE_TYPE_MODAL = 3
/**
 * 打开新页面
 * @namespace 事件响应方式 */
export const INTERFACE_TYPE_NEW_PAGE = 4
/**
 * 跳转链接
 * @namespace 事件响应方式 */
export const INTERFACE_TYPE_LINK = 5
/**
 * 直连弹框
 * @namespace 事件响应方式 */
export const INTERFACE_TYPE_DIRECT_CONNECT_MODAL = 6


/**
 * `form` 类型、不带边框的 `description` 类型
 * @namespace `layout` 枚举 */
export const LAYOUT_TYPE_FORM = 10
/**
 * `table`、`list` 类型
 * @namespace `layout` 枚举 */
export const LAYOUT_TYPE_TABLE = 20
/**
 * 带有 `children` 类型
 * @namespace `layout` 枚举 */
export const LAYOUT_TYPE_CHILDREN = 30
/**
 * 带边框的 `description` 类型
 * @namespace `layout` 枚举 */
export const LAYOUT_TYPE_BORDERED_DESCRIPTION = 40


/**
 * 政府端/监管端
 * @namespace 系统类型 */
export const SYSTEM_TYPE_GOV_SIDE = 'gov'
/**
 * 企业端
 * @namespace 系统类型 */
export const SYSTEM_TYPE_ENT_SIDE = 'ent'


/**
 * 异常
 * @namespace 银行直连状态 */
export const BANK_CONNECTION_STATUS_ABNORMAL = 0
/**
 * 正常
 * @namespace 银行直连状态 */
export const BANK_CONNECTION_STATUS_NORMAL = 1


/**
 * 省
 * @description 地区级别 */
export const AREA_LEVEL_PROVINCE = 'province'
/**
 * 市
 * @description 地区级别 */
export const AREA_LEVEL_CITY = 'city'
/**
 * 区
 * @description 地区级别 */
export const AREA_LEVEL_DISTRICT = 'district'


/**
 * 待开始
 * @namespace 项目状态 */
export const PROJECT_STATUS_TO_BEGIN = '1'
/**
 * 在建
 * @namespace 项目状态 */
export const PROJECT_STATUS_UNDER_CONSTRUCTION = '2'
/**
 * 竣工
 * @namespace 项目状态 */
export const PROJECT_STATUS_COMPLETED = '3'
/**
 * 停工
 * @namespace 项目状态 */
export const PROJECT_STATUS_STOP_WORK = '4'


/**
 * 免缴
 * @namespace 申请类型 */
export const PAYMENT_TYPE_EXEMPTION = '1'
/**
 * 缓缴
 * @namespace 申请类型 */
export const PAYMENT_TYPE_DEFERREED = '2'
/**
 * 一般缴纳
 * @namespace 申请类型 */
export const PAYMENT_TYPE_GENERAL = '3'
/**
 * 组合缴纳
 * @namespace 申请类型 */
export const PAYMENT_TYPE_COMBINATION = '4'


/**
 * 按比例申请
 * @namespace 定额申请方式 */
export const QUOTA_MODE_PROPORTIONALLY = '1'
/**
 * 按金额申请
 * @namespace 定额申请方式 */
export const QUOTA_MODE_BY_AMOUNT = '2'


/**
 * 免缴
 * @description 缴纳方式 */
export const PAYMENT_METHOD_EXEMPTION = '10'
/**
 * 缓缴
 * @description 缴纳方式 */
export const PAYMENT_METHOD_DEFERREED = '20'
/**
 * 现金存缴
 * @description 缴纳方式 */
export const PAYMENT_METHOD_CASH = '30'
/**
 * 保函
 * @description 缴纳方式 */
export const PAYMENT_METHOD_GUARANTEE = '32'


/**
 * 保证保险
 * @namespace 保函类型 */
export const GUARANTEE_TYPE_GUARANTEE_INSURANCE = 34
/**
 * 银行保函
 * @namespace 保函类型 */
export const GUARANTEE_TYPE_BANK_GUARANTEE = 31
/**
 * 保函类型枚举值映射文案
 * @namespace 保函类型 */
export const GUARANTEE_TYPE_MAPPING = {
    [GUARANTEE_TYPE_GUARANTEE_INSURANCE]: '保证保险',
    [GUARANTEE_TYPE_BANK_GUARANTEE]: '银行保函'
}


/**
 * 初始化（保函/现金存缴）
 * @namespace 申请状态 */
export const PROCESS_TYPE_INIT_TYPE = 0
/**
 * 已申请（保函/现金存缴）
 * @namespace 申请状态 */
export const PROCESS_TYPE_DONE_TYPE = 1
/**
 * 已取消（保函/现金存缴）
 * @namespace 申请状态 */
export const PROCESS_TYPE_CANCEL_TYPE = 10
/**
 * 已上传（保函/现金存缴）
 * @namespace 申请状态 */
export const PROCESS_TYPE_UPLOADED_TYPE = 2
/**
 * 已完成（保函/现金存缴）
 * @namespace 申请状态 */
export const PROCESS_TYPE_COMPLETE_TYPE = 3
/**
 * @property {number} 0 初始化（保函/现金存缴）
 * @property {number} 1 已申请（保函/现金存缴）
 * @property {number} 10 已取消（保函/现金存缴）
 *
 * @description 特定申请状态下需展示的空文本 */
export const PROCESS_TYPE_MAPPING_EMPTY_TEXT = {
    [PROCESS_TYPE_INIT_TYPE]: '暂无信息',
    [PROCESS_TYPE_DONE_TYPE]: '企业已发送申请，待金融机构上传数据',
    [PROCESS_TYPE_CANCEL_TYPE]: '金融机构已取消处理'
}


/**
 * 未处理
 * @namespace 续保状态 */
export const RENEWAL_STATUS_INIT = 0
/**
 * 已续保
 * @namespace 续保状态 */
export const RENEWAL_STATUS_SUCCESS = 1
/**
 * 续保处理中
 * @namespace 续保状态 */
export const RENEWAL_STATUS_PROCESSING = 2
/**
 * 已申请
 * @namespace 续保状态 */
export const RENEWAL_STATUS_APPLY = 3


/**
 * 银行
 * @namespace 机构类型 */
export const INST_TYPE_BANK = 'bank'
/**
 * 保险
 * @namespace 机构类型 */
export const INST_TYPE_INSURANCE = 'insurance'
/**
 * 担保
 * @namespace 机构类型 */
export const INST_TYPE_GUARANTEE = 'guarantee'


/**
 * 现金存缴
 * @namespace 动用方式 */
export const UTILIZATION_METHOD_CASH_PAYMENT = 30
/**
 * 保证保险
 * @namespace 动用方式 */
export const UTILIZATION_METHOD_GUARANTEE_INSURANCE = 34
/** 银行保函
 * @namespace 动用方式 */
export const UTILIZATION_METHOD_BANK_GUARANTEE = 31


/**
 * 处理中
 * @namespace 动用处理状态 */
export const UTILIZATION_PROCESS_STATE_DOING = 0
/**
 * 处理完成
 * @namespace 动用处理状态 */
export const UTILIZATION_PROCESS_STATE_DONE = 1
/**
 * 处理取消
 * @namespace 动用处理状态 */
export const UTILIZATION_PROCESS_STATE_CANCELED = 2


/**
 * 施工总包单位
 * @namespace 承建类型 */
export const CONTRACTOR_TYPE_CONSTRUCTION_GENERAL_CONTRACTOR = '1'
/**
 * 建设单位
 * @namespace 承建类型 */
export const CONTRACTOR_TYPE_CONSTRUCTION = '2'
/**
 * 监理单位
 * @namespace 承建类型 */
export const CONTRACTOR_TYPE_SUPERVISION = '3'
/**
 * 设计单位
 * @namespace 承建类型 */
export const CONTRACTOR_TYPE_DESIGN = '4'
/**
 * 勘察单位
 * @namespace 承建类型 */
export const CONTRACTOR_TYPE_EXPLORATION = '5'


/**
 * 布尔值 `false`
 * @namespace 布尔值枚举格式 */
export const BOOLEAN_PATTERN_FALSE = 0
/**
 * 布尔值 `true`
 * @namespace 布尔值枚举格式 */
export const BOOLEAN_PATTERN_TRUE = 1


/**
 * 是
 * @namespace 是/否 */
export const WHETHER_SELECTED_NO = BOOLEAN_PATTERN_FALSE
/**
 * 否
 * @namespace 是/否 */
export const WHETHER_SELECTED_YES = BOOLEAN_PATTERN_TRUE
/**
 * 是/否选项
 * @namespace 是/否 */
export const WHETHER_SELECT_LIST = [
    {
        label: '是',
        value: WHETHER_SELECTED_YES
    },
    {
        label: '否',
        value: WHETHER_SELECTED_NO
    }
]


/**
 * 申请中
 * @namespace 备案申请状态 */
export const FILLING_APPLY_STATE_APPLING = 20
/**
 * 已完成
 * @namespace 备案申请状态 */
export const FILLING_APPLY_STATE_DONE = 21
/**
 * 已驳回
 * @namespace 备案申请状态 */
export const FILLING_APPLY_STATE_REJECTED = 22
/**
 * 已取消
 * @namespace 备案申请状态 */
export const FILLING_APPLY_STATE_CACELLED = 23


/**
 * 缴纳方式启用备案审批
 * @namespace 缴纳方式启用/停用备案审批 */
export const PAYMENT_METHOD_FILLING_APPLICATION_ENABLED = false
/**
 * 缴纳方式停用备案审批
 * @namespace 缴纳方式启用/停用备案审批 */
export const PAYMENT_METHOD_FILLING_APPLICATION_DISABLED = true
/**
 * 根据 `缴纳方式启用/停用备案审批` 展示不同的二次确认提示文案
 * @namespace 保证金详情 / 缴纳备案 / 确认备案前的二次确认提示文案 */
export const CONFIRM_FILLING_POPCONFIRM_TITLE_FACTORY = {
    [PAYMENT_METHOD_FILLING_APPLICATION_ENABLED]: '是否确认备案，确认操作后将进入备案审批流程',
    [PAYMENT_METHOD_FILLING_APPLICATION_DISABLED]: '是否确认备案，确认操作后将完成自动备案，备案后数据将不可变更'
}


/**
 * 合肥市
 * @namespace 主管地区code */
export const MANAGER_AREA_CODE_HEFEI = '340100'


/**
 * 引江济淮
 * @namespace 工程类型 */
export const PROJECT_TYPE_YJJH = 'yjjh'
/**
 * 高速公路
 * @namespace 工程类型 */
export const PROJECT_TYPE_EXPRESSWAY = 'expressway'
/**
 * 轨道交通
 * @namespace 工程类型 */
export const PROJECT_TYPE_RAIL_TRAFFIC = 'rail_traffic'


/**
 * 开
 * @namespace 根据 `项目类型` 自动匹配 `主管地区` 的开关 */
export const AUTOMATICALLY_MATCHES_SUPERVISOR_REGION_ENABLED = '1'


/**
 * 开
 * @namespace 请求获取 `申请比例、申请金额`  的开关 */
export const ADVANCE_PAYMENT_APPLICATION_GET_AMOUNT_ENABLED = '1'


/**
 * 开
 * @namespace 忘记密码开关 */
export const HIDE_ENT_PWD_FORGET_BUTTON_ENABLED = '1'
