<template>
    <custom-upload-file :file-list="fileList" :max-upload-file-limit="fileNumber || 5" :file-size-limit="fileSize || 100" @on-change="handleChange" />
</template>

<script>
import CustomUploadFile from '@/components/CustomUploadFile'
export default {
    name: 'UploadFile',
    components: {
        CustomUploadFile
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        fileNumber: {
            type: Number,
            default: 5
        },
        fileSize: {
            type: Number
        }
    },
    data() {
        return {}
    },
    computed: {
        fileList: {
            get() {
                let _fileList = []
                try {
                    _fileList = JSON.parse(this.value || '[]')

                    if (!Array.isArray(_fileList)) {
                        throw Error('数据格式不对')
                    }
                } catch (e) {
                    _fileList = []
                }

                return _fileList
            },
            set() {
                // console.log(value)
            }
        }
    },
    methods: {
        handleChange(res) {
            if (res.fileList.length > 0) {
                this.$emit('input', JSON.stringify(res.fileList))
                this.$emit('change', JSON.stringify(res.fileList))
            } else {
                this.$emit('input', '')
                this.$emit('change', '')
            }
        }
    }
}
</script>

<style></style>
