<template>
    <a-range-picker
        v-model="localValue"
        :show-time="showTime"
        @change="changeHandle"
    />
</template>

<script>
import { DatePicker } from 'ant-design-vue'

import moment from 'moment'
export default {
    name: 'DateRangePicker',
    components: {
        ARangePicker: DatePicker.RangePicker
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        showTime: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: []
        }
    },
    computed: {
        formatString() {
            return this.showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.localValue = val.split('|').map(item => moment(item))
                } else {
                    this.localValue = []
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        changeHandle(value) {
            this.$emit(
                'input',
                value.map(item => item.format(this.formatString)).join('|')
            )
            this.$emit(
                'change',
                value.map(item => item.format(this.formatString)).join('|')
            )
        }
    }
}
</script>

<style></style>
