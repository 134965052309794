<template>
    <div class="upload-file" :style="`width: ${width}px;`">
        <div v-show="fileProfile.length > 0" class="upload-file-list">
            <div
                v-for="(item, index) in fileProfile"
                :key="item.uid"
                class="upload-file-list__item"
                @mouseover="handleMouseover(index)"
                @mouseout="handleMouseout"
            >
                <div class="content">
                    <img
                        :src="fileIcon(item)"
                        alt=""
                        class="upload-file-list__item-icon"
                    >
                    <a :href="getRelativeAgreementUrl(item.url)"
                       download
                       target="_blank"
                       :title="item.name"
                       class="upload-file-list__item-name">
                        {{ item.name }}
                    </a>
                    <div class="upload-file-list__item-right">
                        <span
                            v-show="showIcon === index"
                            class="upload-file-list__item--actions"
                        >
                            <!-- 自定义内容 -->
                            <span
                                v-if="$slots.deleteIcon"
                                class="upload-file-list__item--delete-icon"
                            >
                                <slot name="deleteIcon" />
                            </span>
                            <!-- 默认文字 -->
                            <span
                                v-else
                                class="upload-file-list__item--delete-text"
                                @click="handleRemove(item)"
                            >{{ deleteText }}</span
                            >
                        </span>
                    </div>
                </div>
                <!-- 进度条 -->
                <div class="progress">
                    <a-progress
                        v-show="item.showProgress"
                        :percent="item.progressPercent || 0"
                        :show-info="false"
                        status="active"
                    />
                </div>
            </div>
        </div>
        <a-upload-dragger
            class="upload-file-dragger"
            :disabled="disabled"
            :accept="accept"
            name="file"
            :headers="headers"
            :file-list="fileProfile"
            :multiple="multiple"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :action="action"
            :data="uploadParams"
            @change="handleChange"
        >
            <p class="ant-upload-drag-icon">
                <img :src="icon" alt="">
                <span class="upload-file__text">{{ text }}</span>
                <template v-if="maxUploadFileLimit !== -1">
                    <span>{{ fileProfile.length }}/{{ maxUploadFileLimit }}</span>
                </template>
            </p>
            <p class="ant-upload-text">{{ _tipText }}</p>
        </a-upload-dragger>
    </div>
</template>

<script>
// 组件
import { Upload as AUpload, Progress as AProgress } from 'ant-design-vue'

import { getFileExtFromPath } from '@utils/file'
import { SAFETY_MFG_URL } from '@config'

import { SYSTEM_TYPE_ENT_SIDE } from '@constant/enum'

import { getToken } from '@utils/auth'
import { getRelativeAgreementUrl } from '@utils'

function customClearInterval(intervalId) {
    return intervalId && clearInterval(intervalId)
}

export default {
    name: 'CustomUploadFile',
    components: {
        AUploadDragger: AUpload.Dragger,
        AProgress
    },
    model: {
        prop: 'fileList',
        event: 'on-change'
    },
    props: {
        // 文件上传地址
        action: {
            type: String,
            // default: 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
            default: `${SAFETY_MFG_URL}/file_operate/upload`
        },
        // 是否多文件上传
        multiple: {
            type: Boolean,
            default: true
        },
        // 文件上传宽度
        width: {
            type: Number,
            default: 320
        },
        // 文件上传默认icon 上传
        icon: {
            type: String,
            default: require('@/assets/images/upload/upload.png')
        },
        // 文件上传默认文字
        text: {
            type: String,
            default: '添加文件'
        },
        // 文件上传默认说明
        tipText: {
            type: String,
            default: `单个文件不超过`
        },
        // 文件右侧icon/文字
        deleteText: {
            type: String,
            default: '删除'
        },
        // 可接受的文件后缀名（扩展名）
        accept: {
            type: String,
            default: '.xls,.xlsx,.mp4,.doc,.docx,.pdf,.jpg,.jpeg,.png,.svg'
        },
        // 单文件大小
        fileSizeLimit: {
            type: Number,
            default: 100
        },
        // 最大上传数量
        maxUploadFileLimit: {
            type: Number,
            default: 5
        },
        // 回显文件列表
        fileList: {
            type: [Array, String],
            default() {
                return []
            }
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false
        },
        // 上传接口其他参数
        uploadParams: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            paramObj: {}, // 额外参数
            fileProfile: [], // 文件列表
            fileIconArray: ['file'], // @/assets/images/upload 文件下icon的name集合
            showIcon: 0 // 显示当前icon,
        }
    },
    computed: {
        // 切换文件类型Icon
        fileIcon() {
            return function (item) {
                const index = item.name?.lastIndexOf('.')
                let extension = ''
                if (index > -1) {
                    extension = item.name.substr(index + 1)
                }

                const isRight = this.fileIconArray.indexOf(extension) > -1
                return isRight
                    ? require(`@/assets/images/upload/${extension}.png`)
                    : require(`@/assets/images/upload/file.png`)
            }
        },
        // 文件大小提示说明
        _tipText() {
            return `${this.tipText} ${this.fileSizeLimit}M`
        },
        // 配置接口请求头
        headers() {
            const headers = {}
            const TOKEN = getToken()
            if (TOKEN) {
                headers['x-authorization-oauth'] = TOKEN
            }
            headers['x-client-type'] = SYSTEM_TYPE_ENT_SIDE
            return headers
        }
    },
    watch: {
        fileList: {
            handler(val) {
                this.uploadInfo(val)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        getRelativeAgreementUrl(url) {
            return getRelativeAgreementUrl(url)
        },
        // 上传前校验
        beforeUpload(file) {
            // 文件上传前额外参数
            // this.paramObj = {
            //     ...this.params,
            //     FileName: file.name
            // }
            this.startLoading?.()
            return new Promise((resolve, reject) => {
                const extension = getFileExtFromPath(file.name) // 获取文件后缀名
                const isRight = this.accept.indexOf(extension) > -1
                const isPass = file.size / 1024 / 1024 < this.fileSizeLimit // 文件大小
                if (!isRight) {
                    this.$message.error(
                        `文件上传类型不正确，请上传后缀名为 ${this.accept} 的文件`
                    )
                    this.endLoading?.()
                    return reject(false)
                } else if (!isPass) {
                    this.$message.error(
                        `请检查上传文件大小是否超过 ${this.fileSizeLimit}MB`
                    )
                    this.endLoading?.()
                    return reject(false)
                } else if (
                    this.fileProfile.length >= this.maxUploadFileLimit &&
                    this.multiple &&
                    this.maxUploadFileLimit > 0
                ) {
                    // 多文件上传，才限制文件个数
                    this.$message.error(
                        `当前限制选择 ${this.maxUploadFileLimit} 个文件，请删除后再上传`
                    )
                    this.endLoading?.()
                    return reject(false)
                }
                return resolve(true)
            })
        },
        /**
         *  文件改变
         */
        async handleChange(data) {
            const { file, fileList } = data
            const status = file.status

            // 添加新的文件
            fileList.forEach(file => {
                if (this.fileProfile.findIndex(item => item.uid === file.uid) === -1) {
                    this.fileProfile.push(file)
                }
            })

            if (status === 'uploading') {
                if (data.event && fileList.length > 0) {
                    for (let i = 0; i < this.fileProfile.length; i++) {
                        if (this.fileProfile[i].uid === file.uid) {
                            if (!this.fileProfile[i].progressTimer) {
                                this.$set(this.fileProfile[i], 'showProgress', true)
                                this.$set(this.fileProfile[i], 'progressPercent', 0)
                                this.uploadProgress(file, i)
                            }
                        }
                    }
                }
            }
            if (status === 'done') {
                let index = this.fileProfile.findIndex(
                    _item => _item.uid === file.uid
                )
                if (index >= 0) {
                    customClearInterval(this.fileProfile[index]['progressTimer'])
                    this.$set(this.fileProfile[index], 'progressPercent', 100)
                }
                if (file.response.code === 500) {
                    customClearInterval(this.fileProfile[index]['progressTimer'])
                    this.fileProfile.splice(index, 1)
                    this.$message.error(`${file.response?.message}`)
                    this.endLoading?.()
                    return
                }
                this.fileProfile.forEach((item, index) => {
                    if (item.uid === file.uid) {
                        this.$nextTick(() => {
                            this.$set(this.fileProfile[index], 'showProgress', false)
                            this.$set(
                                this.fileProfile[index],
                                'progressTimer',
                                null
                            )
                        })
                    }
                })

                // 上传都成功之后进行完成提示
                if (this.fileProfile.every(file => file?.response?.result)) {
                    this.$emit('on-change', {
                        fileList: this.transformData(this.fileProfile)
                    })
                    this.endLoading?.()
                    this.$message.success('上传成功')
                }

            } else if (status === 'error') {
                this.fileProfile.forEach((item, index) => {
                    if (item.uid === file.uid) {
                        customClearInterval(this.fileProfile[index]['progressTimer'])
                        this.$nextTick(() => {
                            this.$set(
                                this.fileProfile[index],
                                'showProgress',
                                false
                            )
                            this.$set(
                                this.fileProfile[index],
                                'progressTimer',
                                null
                            )
                        })
                    }
                })

                this.$emit('on-change', {
                    fileList: this.transformData(this.fileProfile)
                })
                this.endLoading?.()
                this.$message.error(`上传失败`)
            }
        },
        /**
         *  进度条
         */
        uploadProgress(file, index) {
            let percent = 0
            let speed = 100 / (file.size / 65000)
            if (this.fileProfile.length > 0 && this.fileProfile[index]) {
                customClearInterval(this.fileProfile[index]['progressTimer'])
                this.fileProfile[index]['progressTimer'] = setInterval(() => {
                    const timer = this.fileProfile[index]
                    if (!timer) return false

                    if (percent < 90) {
                        percent += speed
                        this.$set(
                            this.fileProfile[index],
                            'progressPercent',
                            percent
                        )
                    } else {
                        customClearInterval(this.fileProfile[index]['progressTimer'])
                    }
                }, 100)
            }
        },
        /**
         *  移除文件
         */
        handleRemove(item) {
            let index = this.fileProfile.findIndex(
                _item => _item.uid === item.uid
            )
            if (index >= 0) {
                customClearInterval(this.fileProfile[index]['progressTimer'])
                this.fileProfile.splice(index, 1)
                this.$emit('on-change', {
                    fileList: this.transformData(this.fileProfile)
                })
                this.endLoading?.()
            } else {
                this.$message.error('文件移除失败')
            }
        },
        /**
         *  删除/icon 显示
         */
        handleMouseover(index) {
            this.showIcon = index
        },
        /**
         *  删除/icon 消失
         */
        handleMouseout() {
            this.showIcon = -1
        },
        /**
         *  数据回传处理
         */
        transformData(fileList) {
            const data = []
            fileList.forEach(item => {
                if (item?.response?.result) {
                    data.push(item.response.result)
                }
            })
            return data
        },
        /*
         * 数据回显
         */
        uploadInfo(fileList) {
            let _fileList = []
            fileList.forEach(item => {
                _fileList.push({
                    uid: item.fileId,
                    name: item.originalFileName || item.fileName || '',
                    status: 'done',
                    response: {
                        result: item
                    },
                    url: item.fileUrl
                })
            })
            this.fileProfile = _fileList
        }
    },
    inject: {
        startLoading: {
            default: () => {}
        },
        endLoading: {
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@assets/styles/varibles.scss';

.upload-file {
    width: 100px;
    position: relative;
    &-list {
        padding: 8px 0;
        background: #fff;
        border-radius: 2px 2px 0 0;
        border: 1px solid #dcdee0;
        border-bottom: none;
        transition: height 0.3s;
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__item {
            padding: 8px 16px;
        }
        .progress {
            line-height:0;
            color: #888;
            height: 2px;
        }
        &__item-icon {
            display: block;
            width: 14px;
            margin-right: 10px;
        }
        &__item-name {
            flex: 1;
            font-size: $font-size;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323233;
            line-height: 20px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &__item-right {
            width: 28px;
            height: 20px;
        }
        &__item--actions {
            display: inline-block;
            vertical-align: top;
            width: 28px;
            font-size: $font-size;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $base-color;
            line-height: 20px;
        }
        &__item--delete-text {
            cursor: pointer;
            font-size: $font-size;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $base-color;
            line-height: 20px;
        }
    }
    &__text {
        margin: 0 6px;
        line-height: 22px;
    }
    ::v-deep {
        .ant-progress {
            // position: absolute;
            line-height: 1;
            // margin-top: -5px;
        }
        .ant-progress-inner {
            height: 2px !important;
        }
        .ant-upload.ant-upload-drag p.ant-upload-drag-icon img {
            display: inline-block;
            width: 18px;
        }

        .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 13px;
        }
        .ant-upload.ant-upload-drag p.ant-upload-text {
            font-size: $font-size-sm;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #969799;
            line-height: 18px;
        }
    }
}
</style>
