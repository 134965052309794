<template>
    <a-select
        v-model="modelValue"
        :loading="loading"
        :disabled="disabled"
        :allow-clear="allowClear"
        :placeholder="placeholder"
        :not-found-content="notFoundContent"
    >
        <div v-if="loading || $slots.notFoundContent" slot="notFoundContent">
            <template v-if="loading">
                <a-spin size="small" />
            </template>
            <template v-else>
                <slot name="notFoundContent" />
            </template>
        </div>

        <template v-for="item in options">
            <a-select-option
                :key="item[optionValue]"
                :value="item[optionValue]"
            >
                {{ item[optionLabel] }}
            </a-select-option>
        </template>
    </a-select>
</template>
<script>
import { Select, Spin } from 'ant-design-vue'

import { isEmpty } from '@utils'

import getOptionsService from '@service/selectService/getFinancialInstitutionListService'

const DEFAULT_OPTION_VALUE = 'institutionCode'
const DEFAULT_OPTION_LABEL = 'companyName'

export default {
    name: 'FinancialInstitutionSelect',
    components: {
        ASpin: Spin,
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            type: [Number, String]
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: true
        },
        optionValue: {
            type: String,
            default: DEFAULT_OPTION_VALUE
        },
        optionLabel: {
            type: String,
            default: DEFAULT_OPTION_LABEL
        },
        notFoundContent: String,
        institutionType: [String, Number]
    },
    data() {
        return {
            options: [],
            mapping: {},
            loading: false
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('change', val)
                this.$emit('input', val)

                this.$emit('on-select-change', this.mapping[val])

                this.$emit('blur')
            }
        }
    },
    watch: {
        institutionType: {
            handler(type) {
                if (!isEmpty(type)) {
                    this.getOptions(type)
                }
            },
            immediate: true
        }
    },
    methods: {
        async getOptions(type) {
            try {
                this.loading = true

                // eslint-disable-next-line
                const params = { institutionType: type }

                const options = await getOptionsService(params)

                this.changeOptions(options || [])
            } catch (e) {
                console.log('-> e', e)
            } finally {
                this.loading = false
            }
        },
        changeOptions(options) {
            this.options = options

            this.generateMapping(options)
        },
        generateMapping(options) {
            const mapping = Object.create(null)

            options.forEach(item => {
                mapping[item[this.optionValue]] = item
            })

            this.mapping = Object.freeze(mapping)
        },
        findOptionByValue(value) {
            return isEmpty(value) ? {} : this.mapping[value] || {}
        },
        findLabelByValue(value) {
            return this.getOptionByValue(value)[this.optionLabel]
        }
    }
}
</script>
